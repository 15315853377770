import React, { useEffect, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { Progress } from 'antd';
import { FilledLightButton, FilledBrightButton } from '@oneblinktech-org/helios-base';
import { connect } from 'react-redux';
import { debouncedGetJobVehicleImgs, debouncedUploadVehicleImgs, initVehicle } from '../../store/job/actions.js';
import { pencil, trash } from '../../fakeData/svgFiles.js';
import { up } from '../../fakeData/svgFiles.js';
import { createApiClient } from '@oneblinktech-org/helios-base';

const VehicleUploader = (props) => {
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [isDragArea, setDragArea] = useState(false)
    const apiUrl = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false)
    const { post } = createApiClient({ apiUrl });

    const {getRootProps, getInputProps, isDragReject } = useDropzone({
        accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
        'image/svg': ['.svg'],
        'application/pdf': ['.pdf']
        },
        onDrop: async acceptedFiles => {
            await setFiles([ ...files, ...acceptedFiles])
            await setDragArea(false)
            const preview = await acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            await setPreviews([...previews, ...preview])
            await onUploadFiles([ ...files, ...acceptedFiles])
        },
        onDragEnter: data => {
            setDragArea(true)
        },
        onDragLeave: data => {
            setDragArea(false)
        },
    });
  
    const thumbs = previews.map((file, index) => (
      <div className="text-bg-light d-flex flex-row justify-content-between align-items-center thumb mt-2" key={file.name}>
        <img
            className="thumb-image mr-2"
            src={file.preview}
            alt=""
        />
        <div className="d-flex flex-column w-100 thumb-details">
            <div className="d-flex flex-row justify-content-between align-items-center thumb-name">
                <span className="sf-pro-display-light image-title">{file.name}</span>
                <span className="sf-pro-display-light image-size">{parseFloat(file.size / 1000000).toFixed(2)} MB</span>
            </div>
            <Progress percent={file.isUploaded? 100 : 0} showInfo={false} />
        </div>
        {
            (file.isUploaded) &&
            <img
                className="ml-1 mr-2 cursor-pointer"
                src={require("../../assets/images/production_board/success-icon.svg").default}
                alt=""
            />
        }
      </div>
    ));

    useEffect(() => {
        const updateStatus = async () => {
            if (props.vehicleImgs.data.status === "success") {
                const uploadedFiles = await files.map(i => Object.assign(i, { isUploaded: true }))
                const uploadedPreviews = await previews.map(i => Object.assign(i, { isUploaded: true }))
                setFiles(uploadedFiles)
                setPreviews(uploadedPreviews)
            }
        }
        updateStatus()
    }, [props.vehicleImgs.data.status])

    useEffect(() => {
        setFiles([])
        setPreviews([])
    }, [props.open])
  
    useEffect(() => {
        props.initVehicle()
        return () => previews.forEach(file => URL.revokeObjectURL(file.preview));
    }, [previews]);

    const onUploadFiles = (files) => {
        if (props.jobId == "" || props.jobId == null || files.length == 0) return;

        const formData = new FormData();

        files.forEach(file => {
            if(!file?.isUploaded)
                formData.append('files[]', file, file.name)
        });

        var data = {
            id: props.jobId,
            files: formData
        }

        props.debouncedUploadVehicleImgs(data);
    }

    const removeUploadImg = (index) => {
        files.splice(index, 1)
        previews.splice(index, 1)

        setFiles([...files]);
        setPreviews([...previews]);
    }

    const handleDragOver = (event) => {
        event.preventDefault(); // Prevent default drag behavior (including green plus)
        if (isDragReject) {
            event.dataTransfer.dropEffect = "none"; // Ensure no-drop icon
        } else {
            event.dataTransfer.dropEffect = "copy"; // Allow drop if accepted
        }
    };

    const handleDiscard = async (event) => {        
        const deleteFiles = await props.vehicleImgs.data?.images?.filter(({ media_name }) => files.some(({ name }) => media_name === name));
        const deleteFileIds = await deleteFiles.map(i => { return i.id?.toString() })
        if (deleteFileIds?.length > 0) {
            setLoading(true)
            const token = await localStorage.getItem('h-access_token')

            await post('/jobs/deletePhotos', { jobImageId: deleteFileIds, jobId: props.jobId }, {
                "Authorization": `Bearer ` + token
            }).then(result => {
                if (result.status == 200 && result.data.message == "Files deleted.") {
                    setPreviews([]);
                    setFiles([]);
                    props.debouncedGetJobVehicleImgs(props.jobId);
                    setLoading(false)
                    props.close()
                }
            }).catch(e => {
                console.log(e)
                setLoading(false)
            });
            
        }
    }

    return (
        <div className="vehicle-uploader d-flex flex-column">
            <div className="sf-pro-display-bold title">Add Vehicle Images</div>
            <section className="mt-3">
                <div {...getRootProps({className: `dropzone ${isDragArea && 'drag-area-color-blue'} ${isDragReject && 'cursor-no-allowed'}` })}
                    onDragOver={handleDragOver} // Handle dragOver to prevent green plus icon
                >
                    <input {...getInputProps()} accept="image/png, image/jpeg, image/jpg, image/svg, application/pdf" />
                    <img className={`drag-drop w-100 ${isDragReject ? 'cursor-no-allowed' : 'cursor-pointer'}`} src={require("../../assets/images/production_board/drag-drop.png").default} alt="" />
                </div>
                <div className="text-bg-light">
                    {thumbs}
                </div>
            </section>
            <div className="d-flex flex-row justify-content-end mt-4">
                <FilledLightButton
                    svgFile={trash}
                    title="Discard and Cancel"
                    clickEvent={(e) => handleDiscard(e)}
                    disabled={props.vehicleImgs.loading || loading}
                />
                <FilledBrightButton
                    svgFile={up}
                    title="Save and Close"
                    clickEvent={(e) => props.close()}
                    disabled={props.vehicleImgs.loading || loading}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        vehicleImgs: state.JobReducer.vehicleImgs
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initVehicle: () => dispatch(initVehicle()),
        debouncedUploadVehicleImgs: (data) => debouncedUploadVehicleImgs(dispatch, data),
        debouncedGetJobVehicleImgs: (id) => debouncedGetJobVehicleImgs(dispatch, id)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleUploader);