import axios from 'axios'

const HELIOS_APP_APP_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: HELIOS_APP_APP_URL,
});

export async function get(url, headers = {}) {
    return await axiosApi
        .get(url, {
            headers: {
                ...headers,
            }    
        })
        .then((response) => response.data)
        .catch(e => {
            if(e.response.status === 401 && e.response.statusText === 'Unauthorized') {
                sessionExpired()
            }
                throw e
        });
}

export async function post(url, payload, headers = {}) {
    return await axiosApi
        .post(url, 
            payload, 
            {
            headers: {
                ...headers,
            }
        })
        .catch(e => {
            if(e.response.status === 401 && e.response.statusText === 'Unauthorized') {
                sessionExpired()
            }
            
            throw e
        });
}

export async function put(url, payload, headers = {}) {
    return await axiosApi
        .put(url, 
            payload, 
            {
            headers: {
                ...headers,
            }
        })
        .catch(e => {
            if(e.response.status === 401 && e.response.statusText === 'Unauthorized') {
                sessionExpired()
            }
            
            throw e
        });
}

export async function remove(url, headers = {}) {
    return await axiosApi
        .delete(url, 
            {
            headers: {
                ...headers,
            }
        })
        .catch(e => {
            if(e.response.status === 401 && e.response.statusText === 'Unauthorized') {
                sessionExpired()
            }
            
            throw e
        });
}

function sessionExpired() {
    localStorage.setItem('h-token_type', '')
    localStorage.setItem('h-access_token', '')
    localStorage.setItem('h-expires_in', '')
}