import { takeLatest, put, call } from 'redux-saga/effects'
import { 
    CREATE_NEW_NOTE, 
    GET_NOTES,
    GET_CHOOSE_NOTE, 
    UPDATE_NOTE, 
    GET_NOTE_LISTS, 
    GET_NOTE_STATUSES,
    UPDATE_NOTE_STATUS,
    GET_COUNTER_DATA
} from './actionTypes'

import {
    getNotesSuccess,
    getNotesFailed,
    createNewNoteSuccess,
    createNewNoteFailed,
    getChooseNoteSuccess,
    getChooseNoteFailed,
    delNoteFailed,
    getNoteStatuesSuccess,
    getNoteStatuesFailed,
    updateNoteStatusSuccess,
    updateNoteStatusFailed,
    getCounterSuccess
} from "./actions"

import { 
    createNewNote,
    updateNote,
    getNotes,
    getNoteLists,
    getNoteStatuses,
    getCounter
} from "../../services/note";

function* onNotes(data) {

    try {
        const response = yield call(getNotes, data.offset);
        yield put(getNotesSuccess(response));
    } catch (error) {
        yield put(getNotesFailed(error.response));
    }
}

function* onNoteLists(data) {

    try {
        const response = yield call(getNoteLists, data.jobId);
        yield put(getNotesSuccess(response));
    } catch (error) {
        yield put(getNotesFailed(error.response));
    }
}

function* onCreateNewNote(data) {

    try {
        const response = yield call(createNewNote, data.payload);
        yield put(createNewNoteSuccess(response, 0));
    } catch (error) {
        yield put(createNewNoteFailed(error, 0));
    }
}

function* onUpdateNote(data) {

    try {
        const response = yield call(updateNote, data.payload);
        yield put(createNewNoteSuccess(response, 1));
    } catch (error) {
        yield put(createNewNoteFailed(error, 1));
    }
}

function* onGetNoteStatuses() {

    try {
        const response = yield call(getNoteStatuses);
        yield put(getNoteStatuesSuccess(response));
    } catch (error) {
        yield put(getNoteStatuesFailed(error));
    }
}

function* onGetCounter() {
    try {
        const response = yield call(getCounter);
        yield put(getCounterSuccess(response))
    } catch (error) {
        
    }
}

function* NoteSaga() {
    yield takeLatest(CREATE_NEW_NOTE, onCreateNewNote)
    yield takeLatest(UPDATE_NOTE, onUpdateNote)
    yield takeLatest(GET_NOTES, onNotes)
    yield takeLatest(GET_NOTE_LISTS, onNoteLists)
    yield takeLatest(GET_NOTE_STATUSES, onGetNoteStatuses)
    yield takeLatest(GET_COUNTER_DATA, onGetCounter)
}

export default NoteSaga;