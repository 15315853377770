import { Controller, } from "react-hook-form";
import NumberFormat from "react-number-format";

export const PhoneInputHF = ({
    name,
    control,
    extraFunctionOnChange,
    defaultValue,
    ...restOptions
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{
                required: 'Phone number is required',
                pattern: {
                  value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                  message: 'Invalid phone number'
                }
            }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
                return (
                    <NumberFormat
                        className="select-box-bottom-line form-control"
                        id="phone_number"
                        value={value}
                        onValueChange={(values) => {
                            onChange(values.value)
                            extraFunctionOnChange?.();
                        }}
                        {...(restOptions ?? {})}
                    />
                );
            }}
        />
    );
};

export const PercentInputHF = ({
    name,
    control,
    extraFunctionOnChange,
    noSelectOnFocus,
    defaultValue,
    ...restOptions
}) => {
    const MAX_VAL = 100;
    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= MAX_VAL) {
            return true;
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ value, onChange }) => {
                return (
                    <NumberFormat
                        className="form-control"
                        value={typeof value === "number" ? value * 100 : value}
                        suffix="%"
                        allowNegative={false}
                        decimalScale={0}
                        isAllowed={withValueCap}
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            onChange(isNaN(floatValue) ? null : floatValue / 100);
                                extraFunctionOnChange?.();
                            }
                        }
                        required
                        onFocus={(e) => {
                            if (!noSelectOnFocus) {
                                setTimeout(() => e.target.select(), 1);
                            }
                        }}
                        {...(restOptions ?? {})}
                    />
                );
            }}
        />
    );
};

export const NumberInputHF = ({
    name,
    control,
    maxValue,
    minValue,
    extraFunctionOnChange,
    onInputChange,
    ...restOptions
}) => {
    const MIN_VAL = minValue ?? Number.NEGATIVE_INFINITY;
    const withValueCap = (inputObj) => {
    const { value } = inputObj;
        if (value <= (maxValue ?? Number.MAX_VALUE)) {
            return true;
        }
    };

    const onBlurValidate = (e, value, onChange) => {
        if (typeof value === "number" && value < MIN_VAL) {
            onChange("");
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ value, onChange }) => {
                return (
                    <NumberFormat
                        className="form-control"
                        value={value}
                        thousandSeparator=","
                        decimalScale={2}
                        isAllowed={withValueCap}
                        onBlur={(e) => onBlurValidate(e, value, onChange)}
                        onValueChange={(values) => {
                        const { floatValue } = values;
                        onChange(floatValue);
                        extraFunctionOnChange?.();
                        }}
                        onChange={onInputChange}
                        required
                        {...(restOptions ?? {})}
                    />
                );
            }}
        />
    );
};

export const PositiveNumberInputHF = (props) => {
    return <NumberInputHF minValue={0} allowNegative={false} {...props} />;
};

export const PositiveIntegerInputHF = (props) => {
    return <PositiveNumberInputHF decimalScale={0} {...props} />;
};
