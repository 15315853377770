import React, { useState, useEffect } from 'react'
import { Select, Tag } from 'antd'
import Dots from '../Dots'
import { useDispatch, useSelector } from 'react-redux'
import { dot_colors } from '../../helpers/globals';

const { CheckableTag } = Tag;

function Filter({ open = false, setFilterOpen = null, colorList = [], setFilterColor = null, jobStatuses = null }) {
    const [filter, setFilter] = useState({});

    const Option = Select.Option;
    const dispatch = useDispatch()
    const dotRefs = React.useRef([]);

    const handleChange = (color, checked) => {
        const nextSelectedTags = checked
            ? [...(filter.colors || []), color]
            : (filter.colors || []).filter(f_color => f_color !== color);
    
        setFilter(prevFilter => {
            const updatedFilter = { ...prevFilter, colors: nextSelectedTags };
            if (nextSelectedTags.length === 0) {
                delete updatedFilter.colors;
            }
            return updatedFilter;
        });
    };
    

    const applyFilter = () => {
        setFilterColor(filter)
        setFilterOpen(false)
    }

    const clearFilter = () => {
        setFilter([])
        setFilterColor([])
    }

    const handleCheckboxChange = (color, checked) => {
        const nextdots = checked
        ? [...(filter.dots || []), color]
        : (filter.dots || []).filter(f_dot => f_dot.id !== color.id);

        setFilter(prevFilter => {
            const updatedFilter = { ...prevFilter, dots: nextdots };
            if (nextdots.length === 0) {
                delete updatedFilter.dots;
            }
            return updatedFilter;
        });
    };

    useEffect(() => {
        dispatch({type: "GLOBAL_FILTER", payload: filter})
    }, [filter])

    return (
        <div className={`filter-modal-wrap ${open ? 'open': ''}`} onClick={() => setFilterOpen(false)}>
            <div className='filter-modal' onClick={e => e.stopPropagation()}>
                <div className='modal-content'>
                    <div className='d-flex flex-wrap gap-2 gap-md-3 row-gap-3'>
                        <Select
                            className='flex-grow-1 half-basis select-min-width'
                            size='large'
                            placeholder='Insurance Company'
                            value={filter?.insurance_company}
                            onChange={(item) => {
                                setFilter({ ...filter, insurance_company: item })
                            }}
                        >
                            <Option value="">All Insurance</Option>
                            {jobStatuses?.uniqueCompanies?.map((company, index) => {
                                return (
                                    company && (
                                        <Option key={index} value={company}>
                                            {company}
                                        </Option>
                                    )
                                )
                            })}
                        </Select>
                        <Select
                            className='flex-grow-1 half-basis select-min-width'
                            size='large'
                            placeholder='Customer'
                            value={filter?.customer}
                            onChange={(item) => {
                                setFilter({ ...filter, customer: item })
                            }}
                        >
                            <Option value="">All Customers</Option>
                            {jobStatuses?.uniqueCustomerNames?.map((customer, index) => {
                                return (
                                    customer && (
                                        <Option key={index} value={customer}>
                                            {customer}
                                        </Option>
                                    )
                                )
                            })}
                        </Select>
                        <Select
                            className='flex-grow-1 select-min-width'
                            size='large'
                            placeholder='Year'
                            value={filter?.year}
                            onChange={(item) => {
                                setFilter({ ...filter, year: item })
                            }}
                        >
                            <Option value="">All Years</Option>
                            {jobStatuses?.uniqueYears?.map((year, index) => {
                                return (
                                    year && (
                                        <Option key={index} value={year}>
                                            {year}
                                        </Option>
                                    )
                                )
                            })}
                        </Select>
                        <Select
                            className='flex-grow-1 select-min-width'
                            size='large'
                            placeholder='Make'
                            value={filter?.make}
                            onChange={(item) => {
                                setFilter({ ...filter, make: item })
                            }}
                        >
                            <Option value="">All Makes</Option>
                            {jobStatuses?.uniqueMakes?.map((make, index) => {
                                return (
                                    make && (
                                        <Option key={index} value={make}>
                                            {make}
                                        </Option>
                                    )
                                )
                            })} 
                        </Select>
                        <Select
                            className='flex-grow-1 select-min-width'
                            size='large'
                            placeholder='Model'
                            value={filter?.model}
                            onChange={(item) => {
                                setFilter({ ...filter, model: item })
                            }}
                        >
                            <Option value="">All Models</Option>
                            {jobStatuses?.uniqueModels?.map((model, index) => {
                                return (
                                    model && (
                                        <Option key={index} value={model}>
                                            {model}
                                        </Option>
                                    )
                                )
                            })}
                        </Select>
                        <div className='d-flex flex-row gap-2 justify-content-between item'>
                            {colorList.map((item, index) => {
                                return (
                                    <div 
                                        key={index} 
                                        className='colour-card' 
                                        onClick={() => handleChange(item.color, !filter?.colors?.includes(item.color))}
                                        style={{ 
                                            border: `4px solid ${filter?.colors?.includes(item.color) ? item.color : '#fff'}`
                                        }}
                                    >
                                        <div className='card-left' style={{ backgroundColor: item?.color }} />
                                        <div className='linegroup'>
                                            <div className='rectangleParent'>
                                                <div className='frameChild' />
                                                <div className='frameChild' />
                                            </div>
                                            <div className='rectangleParent'>
                                                <div className='frameInner' />
                                                <div className='frameChild' />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='item-modal flex-grow-1' >
                            <div className={`d-flex flex-row gap-2 `} onClick={(e) => e.stopPropagation()} >
                                {dot_colors.map((color, index) => (
                                    <div 
                                        key={`${index}`} 
                                        className='dot' style={{ border: `1px solid ${color.color}` }} 
                                        title={color.title}
                                        ref={(el) => (dotRefs.current[index] = el)}
                                        tabIndex={-1}
                                        onClick={() => handleCheckboxChange(color, !filter?.dots?.includes(color))}
                                    >
                                        <div className='dot-inner' style={{ backgroundColor: filter?.dots?.includes(color) ? color.color : '' }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button className='btn-clear flex-grow-1' onClick={() => clearFilter()}>Clear</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter