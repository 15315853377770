import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Divider, Spin, Modal, Skeleton } from 'antd';
import VehicleUploader from '../modals/VehicleUploader';
import { FilledBrightButton, FilledLightButton, ApiSpin } from '@oneblinktech-org/helios-base';
import { plus, send_white, trash } from '../../fakeData/svgFiles.js';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import add_photo from "../../assets/images/production_board/add_photo.png"; 
import { lightOrDark, SUPPORT_URL } from '../../helpers/globals';
import {
    debouncedGetJobVehicleImgs
} from '../../store/job/actions';
import { grayColor } from '../../helpers/globals';
import { notification } from 'antd';
import AddNodes from '../modals/AddNodes';
import { createApiClient } from '@oneblinktech-org/helios-base';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment'
import Block from '../common/block.js';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, put, remove } = createApiClient({ apiUrl });

const PhotoManagement = (props) => {

    const [uploaderOpen, setUploaderOpen] = useState(false);
    const [imgOpen, setImgOpen] = useState(false);
    const [imgIndex, setImgIndex] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [fileCount, setFileCount] = useState(0);
    const [images, setImages] = useState([])
    const [noteOpen, setNoteOpen] = useState(false);
    const [files, setFiles] = useState(false);
    const [backUpData, setBackUpData] = useState({})
    const [downloadSpin, setDownloadSpin] = useState(false);
    const [isDragging, setDragging]=useState(false)
    const [filesOpen, setFilesOpen] = useState(true)
    const [vehicleImgs, setVehicleImgs] = useState([])
    const dispatch = useDispatch()
    const {isReset,setReset, setAttachments, setShowEditor} = props
    
    const [isError, setISError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4, // Adjust as needed
        slidesToScroll: 4, // Adjust as needed
        rows: 2, // Two rows
        vertical: false,
        swipeToSlide: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,
                }
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    rows: 3,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    vertical: false,
                    variableWidth: true,
                }
            },
        ]
    };  
    
    useEffect(() => {
        if (props.item.id != "" && Object.keys(props.item)?.length > 0) {
            props.debouncedGetJobVehicleImgs(props.item.id);
            setSelectedFiles({});
            props.vehicleImgs.data.images = [];
            setFileCount(0);
        }
    }, [props.vehicleImgs?.length]);

    useEffect(() => {
        if (props.vehicleImgs.data.images?.length > 0) {
            setVehicleImgs(props?.vehicleImgs?.data?.images)
        } else
            setVehicleImgs([])
    }, [props.vehicleImgs.data?.images]);

    useEffect(() => {
        if (props.sharedReply) {
            setNoteOpen(true)
        }
    }, [props.sharedReply])

    useEffect(() => {
        let selectedFilesKey = Object.keys(selectedFiles).filter(key => selectedFiles[key] == true).map(Number)
        setFiles(vehicleImgs.filter(item => selectedFilesKey.includes(item.id)))

        var count = Object.values(selectedFiles).filter(item =>  item === true);
        setFileCount(count);
    }, [selectedFiles]);

    useEffect(() => {
        if (isReset){
            setSelectedFiles({})
            setFileCount(0)
        }
    }, [isReset])

    const onChoosePhotos = (e, checked, name) => {
        setReset(false)
        setSelectedFiles({...selectedFiles, [name]: !checked});
    };

    const onClickFile = (e, key) => {
        if (isDragging) {
            e.preventDefault()
            return
        }
        let onlyImages = props.vehicleImgs['data']['images'];
        setImages(onlyImages)
        setImgIndex(key)
    
        setImgOpen(true)
    }

    const sendFiles = () => {
        let filteredFiles = Object.keys(selectedFiles).filter(key => selectedFiles[key] == true)
        
        if (filteredFiles.length > 0) {
            const token = localStorage.getItem('h-access_token')
            
            post('/jobs/sendPhotos', {data: filteredFiles, jobId: props.item.id}, {
                "Authorization": `Bearer ` + token
            }).then(result => {
                if (result.data.message == 'success') {
                    notification.open({
                        message: 'Alert!',
                        description: "Sent selected files successfully!",
                        onClick: () => {
                        },
                    });

                    setSelectedFiles({})
                } else {
                    notification.open({
                        message: 'Alert!',
                        description: "Failed sending of files",
                        onClick: () => {
                        },
                    });
                }
            }).catch(e => {
                return e
            })
        }
    }

    const downloadPhotos = () => {
        let filteredFiles = Object.keys(selectedFiles).filter(key => selectedFiles[key] === true);
        setDownloadSpin(true)
        if (filteredFiles.length > 0) {
            if (filteredFiles.length === 1) {
                // For a single file, generate a direct download link
                const fileUrl = getFileUrl(filteredFiles[0]); // Retrieve the URL for the single selected file

                fetch(fileUrl)
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = url;
                    link.download = getFileName(fileUrl);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);  // Clean up the URL object

                    setDownloadSpin(false)
                    setSelectedFiles({});
                    setFileCount(0)
                })
                .catch(e => {
                    setDownloadSpin(false);
                    setSelectedFiles({});
                    setFileCount(0)
                });
            
            } else {
                // For multiple files, send a request to the API
                const token = localStorage.getItem('h-access_token');
                
                post('/jobs/downloadPhotos', {data: filteredFiles, jobId: props.item.id}, {
                    "Authorization": `Bearer ` + token
                }).then(result => {
                    if (result?.data?.url) {
                        // Create a download link for the zip file received from the API
                        var link = document.createElement('a');
                        link.href = result?.data?.url;
                        link.download = `file-${moment().format('YMMDDHHmm')}.zip`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        setDownloadSpin(false);
                        setSelectedFiles({});
                        setFileCount(0)
                    }
                }).catch(e => {
                    setDownloadSpin(false);
                    setSelectedFiles({});
                    setFileCount(0)
                });
            }
        }
    };

    const delPhotos = () => {
        let filteredFiles = Object.keys(selectedFiles).filter(key => selectedFiles[key] === true);
        const token = localStorage.getItem('h-access_token');
        if(filteredFiles.length && window.confirm(`Are you sure you wish to delete ${filteredFiles.length} images?`)) {
            setDownloadSpin(true)
            
            post('/jobs/deletePhotos', {jobImageId: filteredFiles, jobId: props.item.id}, {
                "Authorization": `Bearer ` + token
            }).then(result => {
                if(result.status == 200 && result.data.message == "Files deleted.") {
                    setDownloadSpin(false)
                    setIsSuccess(true)
                    let imgs = vehicleImgs.filter(item => !filteredFiles.includes(String(item.id)))
                    setVehicleImgs(imgs);
                    setFileCount(0)
                    setSelectedFiles({})
                }
            }).catch(e => {
                setDownloadSpin(false)
                setISError(true)
                setErrorMsg("Failed deleting of files")
            });
        }
    }

    // Utility function to extract the file name from a URL
    function getFileName(url) {
        return url.substring(url.lastIndexOf('/') + 1);
    }

    function getFileUrl(fileId) {
        const file = vehicleImgs.find(item => item.id == fileId);
        return file ? file.media_path : null;
    }
    
 

    const onRemoveFile = (id) => {
        setSelectedFiles({...selectedFiles, [id]: false});
    }

     const onNoteModal = (data) => {
        if(data == undefined) {
            setBackUpData({})
        } else {
            setBackUpData(data)
        }
        
        setNoteOpen(false)
     }
    
    async function addFilesInNote() {
        let selectedFilesKey = await Object.keys(selectedFiles).filter(key => selectedFiles[key] == true).map(Number)
        await setAttachments(vehicleImgs.filter(item => selectedFilesKey.includes(item.id)))
        setShowEditor(true)
    }

    return (
        <div className="photomanagement d-flex flex-column justify-content-between h-full">
            <ApiSpin loading={downloadSpin} error={isError} success={isSuccess} error_txt={errorMsg} support_url={SUPPORT_URL} />
            <Spin spinning={downloadSpin}>
                <Block
                    title='Files'
                    isOpen={filesOpen}
                    onToggle={() => {
                        let filesOpenN = !filesOpen
                        setFilesOpen(filesOpenN)
                    }}
                >
                    <div className='photomanagement-photos'>

                        <div className='add_icon mb-2'>
                            <span>Select files if you want to attach them to a note</span>
                        </div>
                        <div className="custom-slider space">
                            {props.vehicleImgs.loading ?
                                <Skeleton active />
                                :
                                <Slider {...settings}>
                                    {
                                        !props.jobArchiveAt &&
                                        <div className="slider-item" onClick={() => setUploaderOpen(!uploaderOpen)} >
                                            <img
                                                className='add-img'
                                                alt=""
                                                src={require("../../assets/images/production_board/add_photo.png").default}
                                            />
                                        </div>
                                    }
                                    {
                                        Object.keys(props.vehicleImgs.data).length > 0 && vehicleImgs.length > 0 &&
                                        vehicleImgs.map((item, key) => {
                                            return (
                                                <div key={key} className="slider-item">
                                                    {
                                                        item.media_type != 'pdf' ?
                                                            <div className={`image-container ${selectedFiles[item.id] ? "selected" : ""}`}>
                                                                <div className={`selected-img`}>
                                                                    <img src={item.media_path} alt={item.media_name} />
                                                                </div>
                                                                <div className={(selectedFiles[item.id] ? 'no-background' : '') + ' check-wrap'}>
                                                                    <div className='flex-grow-1 p-1' onClick={event => onChoosePhotos(event, !!selectedFiles[item.id], item.id)}>
                                                                        {selectedFiles[item.id] ?
                                                                            <svg className='align-top' width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={event => onChoosePhotos(event, !!selectedFiles[item.id], item.id)}>
                                                                                <g id="Form Elements/Checkbox/Large/Icon/Fully Rounded/Checked">
                                                                                    <path id="Background" fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#006EFF" />
                                                                                    <path id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M17.8532 6.94904C18.0326 6.76101 18.2389 6.66699 18.4722 6.66699C18.7054 6.66699 18.9117 6.76101 19.0911 6.94904C19.2526 7.11828 19.3333 7.32981 19.3333 7.58366C19.3333 7.83751 19.2526 8.04904 19.0911 8.21827L10.5064 17.2721C10.4885 17.2909 10.4705 17.3097 10.4526 17.3285C10.4347 17.3473 10.4167 17.3661 10.3988 17.3849C10.2373 17.573 10.0355 17.667 9.79327 17.667C9.55107 17.667 9.34924 17.573 9.18777 17.3849L4.90887 12.9285C4.7474 12.7593 4.66667 12.5478 4.66667 12.2939C4.66667 12.0401 4.7474 11.8285 4.90887 11.6593C5.08828 11.4713 5.2946 11.3772 5.52783 11.3772C5.76106 11.3772 5.96738 11.4713 6.14679 11.6593L9.77982 15.467L17.8532 6.94904Z" fill="white" />
                                                                                </g>
                                                                            </svg>
                                                                            :
                                                                            <svg className='align-top' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={event => onChoosePhotos(event, !!selectedFiles[item.id], item.id)}>
                                                                                <path id="Color" fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 11.25C3.10051 11.25 0.75 8.8995 0.75 6C0.75 3.10051 3.10051 0.75 6 0.75C8.8995 0.75 11.25 3.10051 11.25 6C11.25 8.8995 8.8995 11.25 6 11.25Z" fill="#FFFFFF" />
                                                                            </svg>
                                                                        }
                                                                    </div>
                                                                    <div className='flex-grow-1 d-flex justify-content-end align-items-end p-1' onClick={(e) => onClickFile(e, key)}>
                                                                        <svg onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            onClickFile(e, key)
                                                                        }} width="24px" height="24px" className={'zoom-icon'} jsaction="click:eQuaEb;focus:AHmuwe; blur:O22p3e" aria-label="Open" viewBox="0 0 24 24" title="Open" role="button" tabIndex="0" jslog="38279; track:click"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm1-7.5h-2v2h-2v2h2v2h2v-2h2v-2h-2z"></path></svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <>
                                                                <div className={`image-container ${selectedFiles[item.id] ? "selected" : ""}`}>
                                                                    <a className='d-table flex-column pdf-icon-wrap selected-img' target='_blank' href={item.media_path} rel="noreferrer">
                                                                        <img src={require('../../assets/images/pdf-icon.png').default} alt={item.media_name} />
                                                                        <p>{item.media_name}</p>
                                                                    </a>

                                                                    <div className={(selectedFiles[item.id] ? 'no-background' : '') + ' check-wrap'}>

                                                                        <div className='flex-grow-1 p-1' onClick={event => onChoosePhotos(event, !!selectedFiles[item.id], item.id)}>
                                                                            {selectedFiles[item.id] ?
                                                                                <svg className='align-top' width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={event => onChoosePhotos(event, !!selectedFiles[item.id], item.id)}>
                                                                                    <g id="Form Elements/Checkbox/Large/Icon/Fully Rounded/Checked">
                                                                                        <path id="Background" fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#006EFF" />
                                                                                        <path id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M17.8532 6.94904C18.0326 6.76101 18.2389 6.66699 18.4722 6.66699C18.7054 6.66699 18.9117 6.76101 19.0911 6.94904C19.2526 7.11828 19.3333 7.32981 19.3333 7.58366C19.3333 7.83751 19.2526 8.04904 19.0911 8.21827L10.5064 17.2721C10.4885 17.2909 10.4705 17.3097 10.4526 17.3285C10.4347 17.3473 10.4167 17.3661 10.3988 17.3849C10.2373 17.573 10.0355 17.667 9.79327 17.667C9.55107 17.667 9.34924 17.573 9.18777 17.3849L4.90887 12.9285C4.7474 12.7593 4.66667 12.5478 4.66667 12.2939C4.66667 12.0401 4.7474 11.8285 4.90887 11.6593C5.08828 11.4713 5.2946 11.3772 5.52783 11.3772C5.76106 11.3772 5.96738 11.4713 6.14679 11.6593L9.77982 15.467L17.8532 6.94904Z" fill="white" />
                                                                                    </g>
                                                                                </svg>
                                                                                :
                                                                                <svg className='align-top' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={event => onChoosePhotos(event, !!selectedFiles[item.id], item.id)}>
                                                                                    <path id="Color" fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 11.25C3.10051 11.25 0.75 8.8995 0.75 6C0.75 3.10051 3.10051 0.75 6 0.75C8.8995 0.75 11.25 3.10051 11.25 6C11.25 8.8995 8.8995 11.25 6 11.25Z" fill="#EAEAEA" />
                                                                                </svg>
                                                                            }
                                                                        </div>
                                                                        <div className='flex-grow-1 d-flex justify-content-end align-items-end p-1' onClick={(e) => onClickFile(e, key)}>
                                                                            <svg onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                onClickFile(e, key)
                                                                            }} width="24px" height="24px" className={'zoom-icon'} jsaction="click:eQuaEb;focus:AHmuwe; blur:O22p3e" aria-label="Open" viewBox="0 0 24 24" title="Open" role="button" tabIndex="0" jslog="38279; track:click"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm1-7.5h-2v2h-2v2h2v2h2v-2h2v-2h-2z"></path></svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            }

                        </div>
                    </div>
                        <div key={fileCount?.length} className={`files-footer ${fileCount.length > 0 ? 'visible' : 'fade-out' }`}>
                            <span className='selected'>
                                Selected {fileCount.length} items
                            </span>
                            <div className='footer-btns'>
                                <div className='btn-icon' onClick={() => downloadPhotos()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M8 12.175 3.175 7.35 4.25 6.275l3 3V0h1.5v9.275l3-3 1.075 1.075L8 12.175ZM1.5 16c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-3.575h1.5V14.5h13v-3.575H16V14.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-13Z" fill="#246DCB" /></svg>
                                </div>
                                {!props.jobArchiveAt &&
                                    <div className='btn-icon' onClick={() => delPhotos()}>
                                        <span dangerouslySetInnerHTML={{ __html: trash }} />
                                    </div>
                                }
                                {!props.jobArchiveAt &&
                                    <div className='file-btns'>
                                        <div className="photomanagement-button-add" onClick={(e) => addFilesInNote()}>
                                            <span>+  Add to Note</span>
                                        </div>
                                        <div className="photomanagement-button-clear" onClick={(e) => { setSelectedFiles({}) }}>
                                            <span>Clear</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                </Block>
                {
                    uploaderOpen &&
                    <Modal
                        className="photomanagement_modal"
                        open={uploaderOpen}
                        footer={null}
                        width={492}
                        onOk={() => setUploaderOpen(false)}
                        onCancel={() => setUploaderOpen(false)}
                        closeIcon={
                            <img
                                className="modal-close"
                                alt=""
                                src={require("../../assets/images/production_board/modal-close.svg").default}
                            />
                        }
                        centered
                    >
                        <VehicleUploader jobId={props.item.id} open={uploaderOpen} close={() => setUploaderOpen(false)} />
                    </Modal>
                }
                {imgOpen && (
                    <Lightbox
                        mainSrc={images[imgIndex]['media_path']}
                        nextSrc={images[(imgIndex + 1) % images.length]['media_path']}
                        prevSrc={images[(imgIndex + images.length - 1) % images.length]['media_path']}
                        onCloseRequest={() => setImgOpen(false)}
                        onMovePrevRequest={() =>
                            setImgIndex((imgIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setImgIndex((imgIndex + 1) % images.length)
                        }
                        imageLoadErrorMessage={
                            <di>{images[imgIndex]['media_type'] === 'pdf' ?
                                <iframe className='lightbox-iframe' src={images[imgIndex]['media_path']} ></iframe>
                                : 'This image failed to load'}
                            </di>
                        }
                        enableZoom={images[imgIndex]['media_type'] === 'pdf' ? false : true}
                    />
                )}
                {
                    noteOpen &&
                    <Modal
                        className="note-modal"
                        open={noteOpen}
                        footer={null}
                        width={492}
                        onOk={() => { setBackUpData({}); setNoteOpen(false); dispatch({ type: 'REPLY_NOTE', payload: false }) }}
                        onCancel={() => { setBackUpData({}); setNoteOpen(false); dispatch({ type: 'REPLY_NOTE', payload: false }) }}
                        closeIcon={
                            <img
                                className="modal-close"
                                alt=""
                                src={require("../../assets/images/production_board/modal-close.svg").default}
                            />
                        }
                        centered
                    >
                        <AddNodes sharedReply={props.sharedReply} currentNoteIndex={props.currentNoteIndex} currentNote={props.currentNote} currentUser={props.currentUser} backUpData={backUpData} jobItem={props.item} jobId={props.item.id} files={files} setNoteOpen={(data) => onNoteModal(data)} removeFile={(id) => onRemoveFile(id)} />
                    </Modal>
                }
            </Spin>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        vehicleImgs: state.JobReducer.vehicleImgs,
        colorList: state.JobReducer.colorList,
        sharedReply: state.NoteReducer.sharedReply,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        debouncedGetJobVehicleImgs: (id) => debouncedGetJobVehicleImgs(dispatch, id)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoManagement);