import React, {useEffect, useState, createRef } from 'react'
import { Link } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import { Button, Input } from 'reactstrap'
import { List, Empty, Modal } from 'antd'
import { get } from '../../helpers/api_helper'
import NewCustomer from '../modals/NewCustomer'

const SearchActions = (props) => {
    const [searchText, setSearchText] = useState("")
    const [jobs, setJobs] = useState([])
    const [customers, setCustomers] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [modal, setModal] = useState(false)
    const divRef = createRef(null)

    const onSearchText = (e) => {
        setSearchText(e.target.value)

        const token = localStorage.getItem('h-access_token')

        get('/smart_search?text=' + e.target.value, {
            "Authorization": `Bearer ` + token
        }).then(result => {
            setJobs(result.jobs)
            setCustomers(result.users)
            setVehicles(result.vehicles)
        }).catch(e => {

        })

    }

    const onNewCustomerClick = () => {
        setModal(!modal)
    }

    const searchRedirect = () => {
        setSearchText('');
    }

    return (
        <>
            <div className="position-relative">
                <div className="input-group search-form">
                    <div className="input-group-prepend">
                        <span className="mx-0 px-0 input-group-text" id="inputGroupPrepend2">
                            <img src={require('../../assets/images/header/search.svg').default} alt="" />
                        </span>
                    </div>
                    <Input autoComplete="off" value={searchText} onChange={(event) => onSearchText(event)} placeholder="Search for client, jobs, vehicle..."  type="text" className="form-control search-input" id="header-search" aria-describedby="inputGroupPrepend2" required />
                    {searchText &&
                        <div className="input-group-prepend">
                            <span className="mx-0 px-0 input-group-text" id="inputGroupPrepend2" onClick={() => setSearchText('')}>
                                 <img
                                     className="clear-search modal-close"
                                     alt=""
                                     src={require("../../assets/images/production_board/modal-close.svg").default}
                                 />
                            </span>
                        </div>
                    }
                </div>
                {
                    searchText &&
                    <div className="position-absolute search_board">
                        {
                            jobs.length > 0 &&
                            <List
                                size="small"
                                header={<div className="search_board_header">OPEN JOBS</div>}
                                dataSource={jobs}
                                renderItem={(item, index) => {
                                    if(index < 4) {
                                        return (
                                            <Link to={{pathname: "/job/edit/" + item.id}} onClick={() => searchRedirect()}>
                                                <List.Item>
                                                    <span
                                                        className="search-item">Job # {item.id} Client: {item['customer']['firstname'] + ' ' + item['customer']['lastname']}</span>
                                                    <span className="detail">
                                                        <span>Job Details</span>
                                                        <span className="arrow">
                                                            <img
                                                                src={require('../../assets/images/right-arrow-color.svg').default}
                                                                alt="" className="close_arrow_left"/>
                                                        </span>
                                                    </span>
                                                </List.Item>
                                            </Link>
                                        )}
                                    }
                                }
                            />
                        }
                        {
                            customers.length > 0 &&
                            <List
                                size="small"
                                header={<div className="search_board_header">CUSTOMERS</div>}
                                dataSource={customers}
                                renderItem={(item, index) => {
                                    if(index < 4) {
                                        return (
                                            <Link to={"/customer/edit/" + item.id} onClick={() => searchRedirect()}>
                                                <List.Item>
                                                    <span className="search-item">{item.firstname + ' ' + item.lastname}</span>
                                                    <span className="detail">
                                                <span>Client Details</span>
                                                <span className="arrow">
                                                    <img src={require('../../assets/images/right-arrow-color.svg').default} alt="" className="close_arrow_left" />
                                                </span>

                                            </span>
                                                </List.Item>
                                            </Link>
                                        )}
                                    }
                                }
                            />
                        }
                        {
                            jobs.length < 1 && customers.length < 1  && vehicles.length < 1 &&
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                        <div className="search_board_footer">
                            <Button outline color="link" size="sm" onClick={() => onNewCustomerClick()} className="add_new">
                                New Customer <span className="plus">+</span>
                            </Button>
                            <Link outline color="link" size="sm" to="/job/add" className="add_new">
                                New Job <span className="plus">+</span>
                            </Link>
                        </div>
                    </div>
                }
            </div>
            <Modal title="Add New Customer" closable={false} open={modal} footer={null} width={750} >
                <NewCustomer closeModal={() => setModal(false)} />
            </Modal>
        </>
    )
}

export default SearchActions