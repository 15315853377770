import { all, fork } from 'redux-saga/effects'
import JobSaga from './job/saga'
import UserSaga from './user/saga'
import NoteSaga from './note/saga'

export default function* rootSaga() {
  yield all([
    fork(JobSaga),
    fork(UserSaga),
    fork(NoteSaga)
  ]);
}