import moment from 'moment'

const format = { TXT_DATE: "MMM DD, h:mm A", TXT_YEAR_DATE: "MMM DD, Y h:mm A", LIST_DATE: "DD-MM-YYYY hh:mm", DUR_DATE: "MMM DD", INOUT_DATE: "MMM D", USER_LOGIN_DATE_FORMAT: "MMM D, YYYY - h:mm A", DATE_ADDED: "MMM DD, Y" };

export const dateFormat = (date, type) => {
    return moment(date).format(format[type])
}

export const formatNumber = (value = '', fractionDigits = 0, preFix = '') => {
    if (parseFloat(value) % 1 === 0)
        return `${preFix}${parseFloat(value)?.toLocaleString()}`
    else
        return `${preFix}${Number(value)?.toLocaleString('en-US', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits })}`
}

export const formatHours = (hour = '', postFix = '') => {
    if (hour) {
        if (parseFloat(hour) % 1 === 0)
            return `${parseFloat((hour)?.toString())}${postFix}`
        else
            return `${parseFloat((hour)?.toString())?.toFixed(1)}${postFix}`
    }
    else
        return `0${postFix}`
}