import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter } from 'react-router-dom'
import store from './store'
import { Provider } from 'react-redux'
import './assets/scss/style.scss'

// import ReactGA from "react-ga4"
// ReactGA.initialize(process.env.REACT_APP_GA4_KEY);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
