import { createApiClient } from '@oneblinktech-org/helios-base';
import { getCookie } from '../helpers/globals';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, remove, put } = createApiClient({ apiUrl });
const headers = { 'Content-Type': 'application/json' }

export function getNoteLists(jobId) {
    const token = localStorage.getItem('h-access_token')

    return get('/notes?jobId=' + jobId, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function getCounter() {
    const token = localStorage.getItem('h-access_token')
    let orgId = getCookie('active_organization_id');

    return get('/global-info/' + orgId, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result;
    }).catch(e => {
        throw e
    })
}

export function createNewNote() {
    
}

export function updateNote() {

}

export function getNotes() {

}

export function getNoteStatuses() {

}