import React from 'react'
import { ReactComponent as ConnectionLink } from "../assets/images/connection-link.svg"

function ConnectionComponent({ connection, setOpenConnectionStep, setActiveConnection, index }) {
    return (
        <div key={index} className={connection?.status === 'active' ? 'connection-card' : 'connection-card inactive'}
            onClick={() => {
                if (connection.status === 'active') {
                    setActiveConnection(connection)
                    setOpenConnectionStep(true)
                }
                else return null
            }}>
            <ConnectionLink fill={connection?.status === 'active' ? '#01459D' : '#5D6F88'} />
            <img className='logo' src={connection?.logo} />

            {connection.status === 'inactive' &&
                <div className="coming-soon-wrapper">
                    <span className="coming-soon">Coming Soon!</span>
                </div>
            }
        </div>
    )
}

export default ConnectionComponent